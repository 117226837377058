<template>
  <VueSlickCarousel v-bind="slickSettings"
    class="slider-product-box"
    v-if="products.length"
    >
    <ProductBox v-for="(product, index) in products" :key="index" :product="product" />
  </VueSlickCarousel>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import axios from "axios";
import ProductBox from "@/components/mains/ProductBox"

export default {
  props: [
    "productListId", "breakpoint481Rows", "slidesToShow"
  ],
  components: {
    VueSlickCarousel,
    ProductBox,
  },
  data() {
    return {
      products: [],
      slickSettings: {
        // centerMode: true,
        // centerPadding: "20px",
        slidesToShow: parseInt(this.slidesToShow) || 5,
        arrows: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: "0px",
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 481,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: "0px",
              slidesToShow: 2,
              rows: this.breakpoint481Rows || 2,
            },
          },
        ],
      },
    }
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY
    },
  },
  async mounted() {
    this.getProductList(this.productListId)
    console.log("============")
    console.log("#2a", this.slidesToShow);
    console.log("#2b", this.breakpoint481Rows)
  },
  methods: {
    getProductList(id) {
      axios.get(this.apiUrl + 'users/products/products_list_static_page/' + id, {
        headers: {api_key: this.apiKey}
      })
      .then(res => {
        this.products = res.data.data
      })
    },
  },
  watch: {
    products(val) {
      console.log("#3a", this.productListId)
      console.log("#3b", val.length)
      console.log("#3c", this.slickSettings.slidesToShow);
      console.log("#3d", this.slickSettings.responsive[1].settings.rows)
    }
  }
}
</script>
<style scoped>
div >>> .product-img-container {
  height: auto;
}

div >>> .product-info {
  line-height: 20px;
}

div >>> :is(.slick-prev, .slick-next)::before {
  font-size: 30px;
  font-weight: 600;
}
</style>
