<template>
  <div>
    <section class="product-list-heading box-my-account" :class="{'pb-5': !isLookbookPage}">
      <div class="text-center" v-if="notFound">
        <img class="mt-3" src="@/assets/img/page-not-found.png" alt="Page not found" />
        <h1 class="mt-3">Oops! Page could not be found</h1>
      </div>
      <div class="box-my-account" :class="setLookbookBackground()" v-if="contentPage && !notFound">
        <div class="container-lg">
          <div class="row">
            <!-- <div class="col-md-12 pt-5 pb-5"> -->
            <div class="col-md-12 pt-5 pb-5" v-if="!isLookbookPage">
              <h1 class="mt-3 text-center">
                {{ contentPage.meta_title }}
              </h1>
            </div>
            <div id="render-web" class="col-md-12 d-none d-lg-block">
              <!-- <div id="content-web"></div> -->
            </div>
            <div id="render-mobile" class="col-md-12 d-lg-none">
              <!-- <div id="content-mobile"></div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { setMetainfo } from "@/_helpers";
import StaticCarousel from "@/components/statics/StaticCarousel.vue";
// import FloatWhatsapp from "@/components/mains/FloatWhatsapp.vue";
// import Chat from "@/components/mains/Chat.vue";

export default {
  name: "StaticPage",
  metaInfo() {
    return setMetainfo(this.notFound ? "Page not found" : this.contentPage?.meta_title, this.notFound ? "Not found" : this.contentPage?.meta_description)
  },
  data() {
    return {
      contentPage: null,
      notFound: null,
    };
  },
  components: {
    // FloatWhatsapp,
    // Chat,
  },
  watch: {
    // getStaticPageData when route change in same StaticPage component
    $route() {
      this.notFound = null;
      this.getStaticPageData();
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    isLookbookPage() {
      return this.$route.params?.page.includes("lookbook");
    }
  },
  mounted() {
    this.getStaticPageData();
    if (
      ["whizliz-reseller", "daftar-reseller-whizliz"].includes(
        this.$route.params.page
      )
    ) {
      document.addEventListener("click", (ev) => {
        // if (["bannerReseller", "bannerResellerMobile"].includes(ev.target.id))
        //   this.firebaseReseller();

        switch (ev.target.id) {
          case "bannerReseller1":
          case "bannerResellerMobile1":
            this.firebaseReseller(1);
            break;
          case "bannerReseller2":
          case "bannerResellerMobile2":
            this.firebaseReseller(2);
            break;
          case "bannerReseller3":
          case "bannerResellerMobile3":
            this.firebaseReseller(3);
            break;
          case "bannerReseller4":
          case "bannerResellerMobile4":
            this.firebaseReseller(4);
            break;
          case "bannerReseller5":
          case "bannerResellerMobile5":
            this.firebaseReseller(5);
            break;
        }
      });
    }
  },
  methods: {
    getStaticPageData() {
      // Untuk Static Content
      if (this.$route.path.split("/")[1] == "static") {
        axios
          .get(this.apiUrl + "users/cms/static/" + this.$route.params.page, {
            headers: { api_key: this.apiKey },
          })
          .then((response) => {
            this.setStaticPageData(response.data.data);
            this.notFound = false;
          })
          .catch((error) => {
            console.log(error);
            this.notFound = true;
            this.contentPage = null;
          });
      }
      // For Lizpedia Content
      else if (this.$route.path.split("/")[1] == "lizpedia") {
        axios
          .get(this.apiUrl + "users/cms/lizpedia/" + this.$route.params.page, {
            headers: { api_key: this.apiKey },
          })
          .then((response) => {
            this.setStaticPageData(response.data.data);
            this.notFound = false;
          })
          .catch((error) => {
            console.log(error);
            this.notFound = true;
            this.contentPage = null;
          });
      }
    },
    setStaticPageData(data) {
      this.contentPage = data;
      setTimeout(() => {
        this.resetRenderWindow()
        const doc = document.createElement("textarea");
        doc.innerHTML = data.content;
        console.log("#1", this.isLookbookPage);
        let template = `<div>${doc.value}</div>`
          .replace(/\[\w+=(\d+)\]/g, `<StaticCarousel productListId="$1" ${this.isLookbookPage ? `slidesToShow="4"` : ''} />`)
          .replace(/\n/g, "")
          .trim();
        Vue.component("StaticCarousel", StaticCarousel);
        new Vue({
          el: "#content-web",
          store: this.$store,
          router: this.$router,
          render: (h) => h(Vue.compile(template)),
        });
        if (data.mobile_content) {
          doc.innerHTML = data.mobile_content;
          template = `<div>${doc.value}</div>`
            .replace(/\[\w+=(\d+)\]/g, `<StaticCarousel productListId="$1" ${this.isLookbookPage ? `breakpoint481Rows="1"` : ''} />`)
            .replace(/\n/g, "")
            .trim();
        }
        new Vue({
          el: "#content-mobile",
          store: this.$store,
          router: this.$router,
          render: (h) => h(Vue.compile(template)),
        });
      }, 500);
      setTimeout(() => {
        window.prerenderReady = true;
      }, 3000)
    },
    resetRenderWindow() {
      const renderWeb = document.getElementById('render-web')
      renderWeb.innerHTML = ''
      const web = document.createElement("div")
      web.setAttribute("id", "content-web")
      renderWeb.appendChild(web)

      const renderMobile = document.getElementById('render-mobile')
      renderMobile.innerHTML = ''
      const mobile = document.createElement("div")
      mobile.setAttribute("id", "content-mobile")
      renderMobile.appendChild(mobile)
    },
    firebaseReseller(params) {
      switch (params) {
        case 1:
          this.$firebaseLogEvent("reseller_mulai_jualan_sekarang");
          break;
        case 2:
          this.$firebaseLogEvent("reseller_daftar_sekarang");
          break;
        case 3:
          this.$firebaseLogEvent("reseller_saya_mau_bergabung");
          break;
        case 4:
          this.$firebaseLogEvent("reseller_langsung_bergabung_sekarang");
          break;
        case 5:
          this.$firebaseLogEvent("reseller_gabung_sekarang");
          break;
      }
    },
    setLookbookBackground() {
      const urlPath = this.$route.params.page;
      if (urlPath.includes("lookbook-serene-collection")) return "lookbook-background-1";
      else return "";
    }
  },
};
</script>

<style scoped>
div >>> p {
  text-align: justify;
  line-height: 180%;
}

div >>> img {
  height: auto !important;
}

div >>> .video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}
div >>> .video-container:after {
    padding-top: 56.25%;
    display: block;
    content: '';
}
div >>> .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
div >>> .slider-product-box :is(.slick-prev, .slick-next):before {
    font-size: 20px;
    width: auto;
    height: auto;
}
div >>> .slider-product-box :is(.slick-prev, .slick-next) {
    width: auto;
}
</style>